<template>
  <div>
    <div class="contentwave_content_selettore">
      <label class="bebas contentwave_content_title" for="editor"
        >Seleziona Editori</label
      >
      <br />
      <select class="contentwave_select_editor" v-model="selectedEditor">
        <option
          v-for="editor in availableEditors"
          :key="editor.cid"
          :value="editor"
        >
          {{ editor.editor.name }}
        </option>
      </select>
      <button
        class="contentwave_select_editor_button"
        @click="addEditor"
        :disabled="!selectedEditor"
      >
        Aggiungi
      </button>
    </div>
    <hr class="infotronik_contentwave_separatore" />
    <div class="infotronik_contentwave_editoriselezionati">
      <div
        v-for="editorComponent in selectedEditors"
        :key="editorComponent.cid"
        class="editor-block"
      >
        <ContentWaveEditor
          :editor="editorComponent"
          @editor-removed="removeEditor"
          @validation-changed="validateEditors"
          @post-confirmed="handlePostConfirmed"
          @post-removed="handlePostRemoved"
          @update:selectedPosts="updateEditorPosts(editorComponent.cid, $event)"
          @update:editorProps="updateEditorProps(editorComponent.cid, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import ContentWaveEditor from "./ContentWaveEditor.vue";
import { generateRandomId } from "@/utils/Common";

export default {
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
  components: {
    ContentWaveEditor,
  },
  emits: [
    "validation-changed",
    "post-confirmed",
    "post-removed",
    "editor-removed",
    "update:selectedEditors",
  ],
  setup(props, { emit }) {
    const selectedEditor = ref(null);
    const selectedEditors = ref([]);

    const availableEditors = computed(() => {
      return props.quote.tags
        .flatMap((tag) => tag.editors)
        .sort((a, b) => a.editor.name.localeCompare(b.editor.name));
    });

    const addEditor = () => {
      if (selectedEditor.value) {
        if (!selectedEditor.value.posts) {
          selectedEditor.value.posts = [];
        }
        const editor = {
          cid: generateRandomId(),
          ...selectedEditor.value,
          posts: [],
          props: {
            ghost: true, // false = in page, true = ghost (solo advertising)
            onlinePostId: null,
            tags: "", // sarà un array di stringhe, nel futuro TODO
            pagelink: null,
          },
        };
        selectedEditors.value.push(editor);
        selectedEditor.value = null;
        validateEditors();
      }
    };

    const removeEditor = (editorComponentId) => {
      const index = selectedEditors.value.findIndex(
        (editorComponent) => editorComponent.cid === editorComponentId
      );
      if (index !== -1) {
        selectedEditors.value.splice(index, 1);
        emit("editor-removed", editorComponentId);
        validateEditors();
      }
    };

    const updateEditorPosts = (editorComponentId, posts) => {
      console.log("new posts arrived");
      const index = selectedEditors.value.findIndex(
        (editorComponent) => editorComponent.cid === editorComponentId
      );
      if (index !== -1) {
        selectedEditors.value[index].posts = posts;
        validateEditors();
      }
    };

    const updateEditorProps = (editorComponentId, props) => {
      const index = selectedEditors.value.findIndex(
        (editorComponent) => editorComponent.cid === editorComponentId
      );
      if (index !== -1) {
        selectedEditors.value[index].props = props;
        //emit("update:selectedEditors", selectedEditors.value);
      }
    };

    const handlePostConfirmed = (post) => {
      emit("post-confirmed", post);
    };

    const handlePostRemoved = (postCid) => {
      emit("post-removed", postCid);
    };

    const validateEditors = () => {
      //console.log("ContentWave.ValidateEditors called. Editors: ", selectedEditors.value);
      const isValid = selectedEditors.value.every(
        (editor) =>
          editor.posts &&
          editor.posts.some((post) => post.confirmed && post.text)
      );
      emit("validation-changed", isValid);
    };
    //watch(selectedEditors, validateEditors, { deep: true });
    watch(
      selectedEditors,
      (newValue) => {
        validateEditors();
        emit("update:selectedEditors", newValue);
      },
      { deep: true }
    );

    return {
      selectedEditor,
      selectedEditors,
      availableEditors,
      addEditor,
      removeEditor,
      validateEditors,
      handlePostRemoved,
      handlePostConfirmed,
      updateEditorPosts,
      updateEditorProps,
    };
  },
};
</script>

<style scoped>
/* titolo pagina */
.contentwave_content_title {
  font-weight: bold;
}

/* selezione editor */
.contentwave_select_editor {
  background: transparent;
  width: 450px;
  border: 1px solid #2cbeff5c;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  box-shadow: none;
  outline: none;
}
.contentwave_select_editor_button {
  margin-left: 10px;
  background: #2cbeff !important;
  color: white !important;
  border: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px !important;
}
.contentwave_select_editor_button:hover {
  color: black !important;
}

/* separatore */
.infotronik_contentwave_separatore {
  width: 80% !important;
  background: #2cbeff !important;
  height: 1px !important;
  margin-top: 30px;
  margin-bottom: 30px;
}

/* lista editori selezionati */
.infotronik_contentwave_editoriselezionati {
  display: grid;
  grid-template-columns: repeat(4, 1fr) !important;
  gap: 5px;
}
@media screen and (max-width: 1470px) {
  .infotronik_contentwave_editoriselezionati {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media screen and (max-width: 1200px) {
  .infotronik_contentwave_editoriselezionati {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (max-width: 950px) {
  .infotronik_contentwave_editoriselezionati {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
.editor-block {
  border: 1px solid #2cbeff9c;
  padding: 10px;
  margin-bottom: 16px;
}
</style>
