<template>
  <!-- Mattia sistema la posizione dello spinner. Decommenta la riga del delay x provarlo (vedi sotto) -->
  <div v-if="isLoading">
    <Spinner id="spinner" />
  </div>
  <div v-else class="contentwave_container">
    <div class="tabs contentwave_tabs_container">
      <button
        @click="selectedTab = 'ContentWave'"
        :class="{ active: selectedTab === 'ContentWave' }"
      >
        ContentWave
      </button>
      <button
        @click="selectedTab = 'InflooAd'"
        :class="{ active: selectedTab === 'InflooAd' }"
      >
        InflooAd
      </button>
      <button
        @click="selectedTab = 'Preview'"
        :class="{ active: selectedTab === 'Preview' }"
      >
        Brief Preview
      </button>
    </div>
    <div
      class="contentwave_content_contentwave"
      v-show="selectedTab === 'ContentWave'"
    >
      <span>
        <h1 class="contentwave_title">
          {{ this.quote?.campaign.name }}
        </h1>
        <h3 class="contentwave_advertiser">
          Advertiser: {{ this.quote?.campaign.advertiser.userName }}
        </h3>
      </span>
      <hr class="infotronik_contentwave_separatore" />
      <ContentWave
        v-if="this.quote"
        :quote="quote"
        @validation-changed="validateContentWave"
        @post-confirmed="handlePostConfirmed"
        @post-removed="handlePostRemoved"
        @editor-removed="handleEditorRemoved"
        @update:selectedEditors="handleSelectedEditorsUpdate"
      />
    </div>
    <div
      class="contentwave_content_inflooad"
      v-show="selectedTab === 'InflooAd'"
    >
      <span>
        <h1 class="contentwave_title">
          {{ this.quote?.campaign.name }}
        </h1>
        <h3 class="contentwave_advertiser">
          Advertiser: {{ this.quote?.campaign.advertiser.userName }}
        </h3>
      </span>
      <hr class="infotronik_contentwave_separatore" />
      <InflooAd
        v-if="this.quote"
        :quote="quote"
        :confirmedPosts="confirmedPosts"
        @validation-changed="validateInflooAd"
        @update:campaignDates="updateDates"
      />
    </div>
    <div
      class="contentwave_content_inflooad"
      v-show="selectedTab === 'Preview'"
    >
      <BriefPreview
        v-if="this.quote"
        :quote="quote"
        :confirmedPosts="confirmedPosts"
        :selectedEditors="selectedEditors"
        :campaignDates="campaignDates"
      />
    </div>
    <hr class="infotronik_contentwave_separatore" />
    <button
      v-show="false"
      class="contentwave_content_saveall"
      @click="saveBrief"
      :disabled="!isContentWaveValid || !isInflooAdValid || !isBriefModified"
    >
      Pubblica
    </button>
    <button class="contentwave_content_saveall" @click="saveBrief">
      Pubblica
    </button>
  </div>

  <ConfirmPanel
    v-if="publishVisible"
    :title="'Pubblicazione campagna'"
    :message="
      'Pubblicazione campagna programmata per la data ' +
      campaignDates.startDate +
      '\n' +
      '\n' +
      'Confermare?'
    "
    :confirmButtonText="'Pubblica'"
    @allow-confirm="publishVisible = false"
    @deny-confirm="publishVisible = false"
  >
  </ConfirmPanel>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import ContentWave from "./ContentWave.vue";
import InflooAd from "./InflooAd.vue";
import BriefPreview from "./BriefPreview.vue";
import { ApiEndPoints } from "@/api/Endpoints";
import Spinner from "@/components/Spinner.vue";
import ConfirmPanel from "@/components/ConfirmPanel";
//import { show } from "../../api/campaigns";

export default {
  name: "CompleteBrief",
  props: {
    cod: {
      type: String,
      required: true,
    },
  },
  components: {
    ContentWave,
    InflooAd,
    BriefPreview,
    Spinner,
    ConfirmPanel,
  },
  setup(props) {
    const selectedTab = ref("ContentWave");
    const quote = ref(null);
    const isLoading = ref(false);

    const isContentWaveValid = ref(false);
    const isInflooAdValid = ref(false);
    const isBriefModified = ref(false);
    const confirmedPosts = ref([]);
    const selectedEditors = ref([]);
    const campaignDates = ref({ startDate: null, endDate: null });
    const publishVisible = ref(false);

    const loadQuote = async () => {
      isLoading.value = true;
      try {
        const response = await axios.get(`${ApiEndPoints.QUOTES}/${props.cod}`);
        // await new Promise((resolve) => setTimeout(resolve, 10000)); // <===== RIGA DEL DELAY DA DECOMMENTARE
        quote.value = response.data;
      } catch (error) {
        console.error("Errore nel caricamento del preventivo:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const handleSelectedEditorsUpdate = (editors) => {
      selectedEditors.value = editors;
    };

    const handlePostConfirmed = (post) => {
      const index = confirmedPosts.value.findIndex((p) => p.cid === post.cid);
      if (index !== -1) {
        confirmedPosts.value[index] = post;
      } else {
        confirmedPosts.value.push(post);
      }
    };

    const handlePostRemoved = (postId) => {
      const index = confirmedPosts.value.findIndex((p) => p.cid === postId);
      if (index !== -1) {
        confirmedPosts.value.splice(index, 1);
      }
    };

    const handleEditorRemoved = (editorComponentId) => {
      // Se ho rimosso un editore, rimuovo i suoi eventuali post confermati
      const index = confirmedPosts.value.findIndex(
        (post) => post.editorCid === editorComponentId
      );
      if (index !== -1) {
        confirmedPosts.value.splice(index, 1);
      }
    };

    const validateContentWave = (isValid) => {
      isContentWaveValid.value = isValid;
      isBriefModified.value = true;
    };

    const validateInflooAd = (isValid) => {
      isInflooAdValid.value = isValid;
      isBriefModified.value = true;
    };

    const saveBrief = () => {
      publishVisible.value = true;
      /*
      const selectedPosts = quote.value.tags.flatMap((tag) =>
        tag.editors.flatMap((editor) =>
          editor.posts.filter((post) => post.selected)
        )
      );
      const strategyPostCount = quote.value.strategies.reduce(
        (acc, strategy) =>
          acc + strategy.goals.reduce((acc, goal) => acc + goal.posts, 0),
        0
      );

      if (selectedPosts.length !== strategyPostCount) {
        if (
          !confirm(
            "Il numero di post selezionati è diverso da quelli indicati in strategia. Sei sicuro di voler salvare?"
          )
        ) {
          return;
        }
      }

      // Logica di salvataggio TODO!
      console.log("Salvataggio effettuato");
      isBriefModified.value = false;
      */
    };

    const updateDates = (value) => {
      campaignDates.value = value;
    };

    onMounted(() => {
      loadQuote();
    });

    return {
      isLoading,
      selectedTab,
      quote,
      isContentWaveValid,
      isInflooAdValid,
      isBriefModified,
      confirmedPosts,
      handlePostConfirmed,
      handlePostRemoved,
      handleEditorRemoved,
      validateContentWave,
      validateInflooAd,
      saveBrief,
      handleSelectedEditorsUpdate,
      selectedEditors,
      updateDates,
      campaignDates,
      publishVisible,
    };
  },
};
</script>

<style scoped>
/* titolo campagna */
.contentwave_title {
  font-family: "Bebas Neue", cursive;
  color: #15465c !important;
}

/* advertiser */
.contentwave_advertiser {
  font-family: "Bebas Neue", cursive;
  font-size: 21px !important;
}

.contentwave_container {
  overflow-y: auto !important;
  width: 100% !important;
  background-color: #f0f8ff !important;
  scrollbar-width: thin;
  scrollbar-color: #2cbeff #ffffff !important;
}

/* tabs contenitore*/
.contentwave_tabs_container {
  display: flex;
  width: 350px;
  float: right;
  margin-right: 20px;
  margin-top: 3px;
}
.contentwave_tabs_container > button:nth-child(1) {
  flex: 1 !important;
  border-bottom: 1px solid #f1f3f4;
  cursor: default;
  font-weight: normal;
  line-height: 35px;
  list-style: none;
  background-color: #ffffff !important;
  color: #000000 !important;
  border-top: 1px solid #2cbeff !important;
  border-right: 1px solid #2cbeff !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 15px;
  box-shadow: none !important;
  outline: none !important;
  border-left: 1px solid #2cbeff !important;
}
.contentwave_tabs_container > button:nth-child(2) {
  flex: 1 !important;
  border-bottom: 1px solid #f1f3f4;
  cursor: default;
  font-weight: normal;
  line-height: 35px;
  list-style: none;
  background-color: #ffffff !important;
  color: #000000 !important;
  border-top: 1px solid #2cbeff !important;
  border-right: 1px solid #2cbeff !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 15px;
  box-shadow: none !important;
  outline: none !important;
  border-left: none !important;
}
.contentwave_tabs_container > button:nth-child(3) {
  flex: 1 !important;
  border-bottom: 1px solid #f1f3f4;
  cursor: default;
  font-weight: normal;
  line-height: 35px;
  list-style: none;
  background-color: #ffffff !important;
  color: #000000 !important;
  border-top: 1px solid #2cbeff !important;
  border-right: 1px solid #2cbeff !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 15px;
  box-shadow: none !important;
  outline: none !important;
  border-left: none !important;
}
.contentwave_tabs_container > .active:nth-child(1) {
  background-color: #f0f8ff !important;
  color: #000000 !important;
  border-top: 1px solid #2cbeff !important;
  border-right: 1px solid #2cbeff !important;
  border-left: 1px solid #2cbeff !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 15px;
}
.contentwave_tabs_container > .active:nth-child(2) {
  background-color: #f0f8ff !important;
  color: #000000 !important;
  border-top: 1px solid #2cbeff !important;
  border-right: 1px solid #2cbeff !important;
  border-left: none !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 15px;
}
.contentwave_tabs_container > .active:nth-child(3) {
  background-color: #f0f8ff !important;
  color: #000000 !important;
  border-top: 1px solid #2cbeff !important;
  border-right: 1px solid #2cbeff !important;
  border-left: none !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 15px;
}
.contentwave_tabs_container > button:hover {
  color: #000000 !important;
  background-color: #f2f2f2 !important;
  font-size: 15px;
}

/* contenitori contenuto generazioni */
.contentwave_content_contentwave {
  margin-top: 60px;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

/* pulsante salvataggio totale */
.contentwave_content_saveall {
  font-size: 18px !important;
  height: 37px !important;
  position: relative !important;
  cursor: pointer !important;
  font-family: "Bebas Neue", cursive !important;
  color: white !important;
  font-weight: normal !important;
  line-height: 18px !important;
  text-align: center !important;
  background-color: #2cbeff !important;
  border: none !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 18px !important;
  padding-left: 18px !important;
  margin-top: 35px !important;
  margin-bottom: 45px !important;
  float: right;
  margin-right: 25px !important;
}
.contentwave_content_saveall:hover {
  color: black !important;
}

/* separatore */
.infotronik_contentwave_separatore {
  width: 80% !important;
  background: #2cbeff !important;
  height: 1px !important;
  margin-top: 30px;
  margin-bottom: 30px;
}

/* contenitore inflooad */
.contentwave_content_inflooad {
  margin-top: 60px;
  padding-left: 24px !important;
  padding-right: 24px !important;
}
</style>
