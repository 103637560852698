<template>
  <div>
    <div>
      <label class="bebas inflooad_content_title" for="inflooad_dates"
        >Validità Campagna</label
      >
      <div class="campaign-dates">
        <label for="start-date">Dal:</label>
        <input
          type="date"
          id="start-date"
          v-model="campaignStartDate"
          :min="today"
        />
        <label for="end-date">Al:</label>
        <input
          type="date"
          id="end-date"
          v-model="campaignEndDate"
          :min="today"
        />
      </div>
    </div>
    <hr class="infotronik_inflooad_separatore" />
    <div>
      <label class="bebas inflooad_content_title" for="inflooad_strategy"
        >Seleziona Strategia</label
      >
      <div class="strategy-grid">
        <StrategyRow
          v-for="strategy in quote.strategies"
          :key="strategy.name"
          :strategy="strategy"
          :selectedGoal="selectedGoal"
          @update:selectedGoal="updateSelectedGoal"
        />
      </div>
    </div>
    <div v-if="editableGoal">
      <label class="bebas inflooad_content_title" for="inflooad_goaldetails"
        >Dettagli Goal Selezionato</label
      >
      <div class="selected-goal-details">
        <div
          class="selected_goal_details_row"
          v-for="(value, key) in editableGoal"
          :key="key"
        > 
          <div v-if="!excludedStrategyFields.includes(key)">
            <label class="selected_goal_details_label" :for="key"
              >{{ key }}:</label
            >
            <input
              class="selected_goal_details_input"
              type="number"
              v-model="editableGoal[key]"
            />
          </div>
        </div>
      </div>
    </div>
    <hr class="infotronik_inflooad_separatore" />
    <div class="post_inflooad_grid">
      <ConfirmedPost
        v-for="post in confirmedPosts"
        :key="post.id"
        :post="post"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import StrategyRow from "./InflooAd_StrategyRow.vue";
import ConfirmedPost from "./ConfirmedPost.vue";

export default {
  props: {
    quote: Object,
    confirmedPosts: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    StrategyRow,
    ConfirmedPost,
  },
  emits: ['update:campaignDates'],
  setup(props, { emit }) {
    const today = new Date().toISOString().split("T")[0];
    const campaignStartDate = ref(today);
    const campaignEndDate = ref(today);
    const selectedGoal = ref(null);
    const editableGoal = ref(null);

    watch([campaignStartDate, campaignEndDate], ([newStartDate, newEndDate]) => {
      emit('update:campaignDates', { startDate: newStartDate, endDate: newEndDate });
    });

    const excludedStrategyFields = ['views_3s', 'views_15s', 'impressions_min', 'impressions_max'];

    const onAllGendersChange = (post) => {
      if (post.target.gender.includes("all")) {
        post.target.gender = ["all"];
      }
    };

    const onAllAgesChange = (post) => {
      if (post.target.age.includes("all")) {
        post.target.age = ["all"];
      }
    };

    const validateGoal = () => {
      if (!selectedGoal.value) {
        return true; // Se non c'è un goal selezionato, consideriamo la validazione come passata
      }

      const totalBudget = props.confirmedPosts.reduce((acc, post) => {
        return acc + (post.budget || 0);
      }, 0);

      const goalBudget = selectedGoal.value.budget;
      if (totalBudget > goalBudget) {
        return false;
      }
      return true;
    };

    const updateSelectedGoal = (goal) => {
      selectedGoal.value = goal;
      editableGoal.value = JSON.parse(JSON.stringify(goal)); // Creiamo una copia modificabile del goal selezionato
      validateGoal();
    };

    watch(selectedGoal, () => {
      validateGoal();
    });

    watch(
      props.confirmedPosts,
      (newPosts) => {
        newPosts.forEach((post) => {
          if (post.budget === null) {
            if (editableGoal.value) {
              post.budget = Math.floor(
                editableGoal.value.budget / editableGoal.value.posts
              );
            } else { 
              post.budget = 0;
            }
          }         
        });
      },
      { deep: true }
    );

    return {
      today,
      campaignStartDate,
      campaignEndDate,
      selectedGoal,
      editableGoal,
      validateGoal,
      onAllGendersChange,
      onAllAgesChange,
      updateSelectedGoal,
      excludedStrategyFields,
    };
  },
};
</script>

<style scoped>
/* blocco per la scelta delle date */
.campaign-dates {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.campaign-dates > label {
  margin-top: 5px;
  font-size: 15px;
}
.campaign-dates > input {
  background-color: #F2F2F2 !important;
  font-size: 15px !important;
  line-height: 28px !important;
  height: 28px !important;
  font-family: inherit !important;
  border: 0 !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  outline: none !important;
}
.campaign-dates > input:focus { 
  outline: none !important;
}

/* griglia strategie ritrovate */
.strategy-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr) !important;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}
@media screen and (max-width: 1470px) {
  .strategy-grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media screen and (max-width: 1200px) {
  .strategy-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (max-width: 950px) {
  .strategy-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

/* elementi della strategia selezionata */
.selected-goal-details {
  margin-bottom: 10px !important;
}
/*.selected-goal-details div {
}*/
.selected_goal_details_row {
  margin-bottom: 5px !important;
}
.selected_goal_details_label {
  font-family: "Bebas Neue", cursive;
  font-size: 18px !important;
}
.selected_goal_details_input {
  background-color: #f2f2f2;
  font-size: 14px;
  line-height: 20px;
  height: 25px;
  font-family: inherit;
  position: relative;
  border: none !important;
  margin-left: 10px !important;
}

/* titolo sezione */
.inflooad_content_title {
  font-weight: bold;
}

/* separatore */
.infotronik_inflooad_separatore {
  width: 80% !important;
  background: #2cbeff !important;
  height: 1px !important;
  margin-top: 30px;
  margin-bottom: 30px;
}

/* griglia specifiche post */
.post_inflooad_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr) !important;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}
@media screen and (max-width: 1470px) {
  .post_inflooad_grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media screen and (max-width: 1200px) {
  .post_inflooad_grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (max-width: 950px) {
  .post_inflooad_grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
</style>
